import React, { Component, createRef, useContext} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import _ from 'lodash'
import Site from "./site";
import { filterSites } from '../selectors';
import { MessageContext } from "@cargo/ui-kit/message/message-controller";
import { AlertContext } from "@cargo/ui-kit/alert/alert";
// import { HotKeyProxy } from "./ui-kit";
import { withRouter } from 'react-router-dom';
import DuplicatingIcon from "@cargo/common/icons/duplicating.svg";
import BlankSiteTileIcon from "@cargo/common/icons/blank-site-tile.svg";
import { SiteDuplicationContext } from './site-duplication-provider';

class SitesList extends Component {

	static contextType = SiteDuplicationContext;

	constructor(props) {
		super(props);

		this.state = {
			altPressed: false,
			isDragging: false,
			draggingSiteId: false,
			draggedSiteModel: null,
			adjacentIndexes: {
				above: -1,
				below: -1,
				left: -1,
				right: -1,
			},
			insertBeforeTarget: null,
			showInsertionIndicator: false,
			isDraggingOverNavigation: false,
			draggedOverFolderId: null,
			mousePos: {
				x: null,
				y: null,
			},
			draggingItemReturnTransition: {
				x: null,
				y: null
			},
			isDropping: false,
			scrollingDirection: null,
			initialScrollY: null,
			sortTransition: false,
			sorting: false,
			previousSortIndexes: null,
			coordinatesByIndex: null,
			prevCoordinatesByIndex: null,
			dragGhostImageLoaded: false,
			sitesForRender: {},
			slug: this.props.slug,
			transitionClassList: 'transitioning-opacity',
		}

		this.mouseDownTarget = null;
		this.draggingTargetPermissions = null;
		this.justClosedSitePreview = false;
		this.dragMap = new Map();
		this.gridRef = React.createRef();
		this.scrollIntervalRef = React.createRef();
		this.transitionMeasureRef = React.createRef();
		this.placeHolderImg = 'https://freight.cargo.site/t/original/i/692fc6a7f6de41ef426ea5182a42cab1e7c15ff89fcc457e9dcd1c3b9bbe5704/placeholder.jpg';
		this.dragStopping = React.createRef();
		this.dragStopping.current = false;
		this.firstFolderRender = true;

	}

	componentDidUpdate( prevProps, prevState ) {

		if( this.firstFolderRender === true &&
			prevState.sitesForRender !== this.state.sitesForRender &&
			this.gridRef.current
		) {
			this.firstFolderRender = false;
			this.fadeInList();
		}

		if( !this.firstFolderRender 
			&& this.props.folder?.id
			&& prevProps.folder?.id
			&& this.props?.folder?.id !== prevProps?.folder?.id 
		){
			this.fadeInList();
		}

		if(
			this.props.folders !== prevProps.folders
			|| this.props.slug !== prevProps.slug
		) {
			try {
				if( ( this.props.slug || this.props.slug === null ) && this.props.folder ) {
					let slugToStore = this.props.slug || '';
					// valid folder path, store it
					localStorage.setItem('last-visted-slug', slugToStore)
				} else {
					// invalid path, remove from storage
					localStorage.removeItem('last-visted-slug')
				}
			} catch(e) {}
		}

		if(this.props.folder !== prevProps.folder) {
			
			// update state with actively rendered folder
			this.props.updateHomepageState({
				renderedFolder: this.props.folder?.id || null
			});

			if(
				this.props.folder
				// this folder is lazy loadable
				&& this.props.folder.lazy_load === true
				// and doesn't have all it's sites yet
				&& this.getSitesForRender().length !== this.props.folder.sites.length
			) {
				// load in missing sites
				this.props.fetchSitesInFolder(this.props.folder.id)
			}

		}

		this.dragMargins = this.transitionMeasureRef?.current?.getBoundingClientRect().width;

		if( prevState.sitesForRender 
			&& this.state.coordinatesByIndex 
			&& prevState.sitesForRender.length !== this.state.sitesForRender.length 
			&& this.state.slug === prevState.slug
			&& this.props.currentSearchTerm === prevProps.currentSearchTerm
		){
			this.transitionSitesOnCollectionChange( prevState.sitesForRender, this.state.sitesForRender )
		}

		// if not set
		// OR if the length of sites changes (adding / removing)
		if ( 
				this.state.coordinatesByIndex === null 
				|| this.props.sites.length !== prevProps.sites.length
				|| this.state.slug !== prevState.slug
				// || this.props.sitesForRender !== prevState.sitesForRender
		) {
			this.setCoordinatesByIndex()
		}

		if(    this.props.sites !== prevProps.sites 
			// || this.props.templates !== prevProps.templates
			|| this.props.sites.length !== prevProps.sites.length
			|| this.props.slug !== prevProps.slug
			|| ( this.props.deletedSites !== prevProps.deletedSites && this.props.slug === 'trash' )
			|| prevProps.folder?.sites?.length !== this.props.folder?.sites?.length
			|| ( this.props.savedFolderId === this.props.folder?.id && this.props.templates !== prevProps.templates )
		){
			this.setSitesForRender();
		}

		if( this.props.slug !== this.state.slug ){
			this.setState({slug: this.props.slug })
		}
		
		if( this.props.currentSearchTerm !== prevProps.currentSearchTerm ){
			this.setSitesForRender();
		}
		
		// this.props.uiWindows.byId['account-manager-window']
		// Cancel dragging when any window is open
		if(    this.state.isDragging 
			&& this.props.hasUiWindow
		 ){
			// remove event on mouseup...
			this.setState({
				droppedIndex: null,
				isDragging: false,
				isDropping: true,
			}, ()=>{
				this.onDragStop();
			})
		}

		if( prevProps.sitePreview.previewingSite === true && this.props.sitePreview.previewingSite === false ){
			// Prevent clicking / pointer events on things after closing the site preview with mousedown.
			this.justClosedSitePreview = true;
			window.addEventListener('mouseup', ()=>{ 
				this.justClosedSitePreview = false;
			}, {once: true})
		}

	}

	fadeInList = () => {
		if( this.state.transitionClassList === 'transitioning-opacity' ){
			this.setState({
				transitionClassList: 'transitioning-opacity fade-in'
			})
		} else {
			this.setState({ transitionClassList: 'transitioning-opacity' }, ()=>{	
				this.setState({
					transitionClassList: 'transitioning-opacity fade-in'
				})
			})
		}
	}

	handleTransitionEnd = () => {
		if( this.state.transitionClassList === 'transitioning-opacity fade-in' ) {
			this.setState({
				transitionClassList: null
			})
		}
	}

	setCoordinatesByIndex = () => {
		let coordinates = [];
		let prevCoordinatesByIndex = this.state.coordinatesByIndex || null;

		let sites = document.querySelectorAll('.site');

		_.each(sites, (site, index) => {
			coordinates[index] = site.getBoundingClientRect();
		})

		this.setState({
			coordinatesByIndex: coordinates,
			prevCoordinatesByIndex: prevCoordinatesByIndex
		});

	}

	setSitesForRender = () => {

		const sites = this.getSitesForRender();

		this.setState({
			sitesForRender: sites
		}, ()=>{
			this.setCoordinatesByIndex();

			if(this.props.hasFolders) {

				// restore scroll position if saved
				try {
					const scrollPos = parseInt(sessionStorage.getItem('lastScrollPosition'));
					// delete after using
					sessionStorage.removeItem('lastScrollPosition');

					if(!isNaN(scrollPos)) {
						document.scrollingElement.scrollTop = scrollPos;
					}
				} catch(e) {
					console.error(e)
				}

			}
		});

	}

	getSitesForRender = () => {

		if( this.props.slug === 'trash' && !this.props.trashFetched ){
			return {}
		}

		let siteList = this.props.slug !== 'trash' ? this.props.sites : this.props.deletedSites;

		if( this.props.savedFolderId && this.props.folder?.id && this.props.savedFolderId === this.props.folder?.id ){
			siteList = [ ...siteList, ...this.props.templates ]
		}

		const sites = filterSites(siteList, {
			deleted: this.props.slug === 'trash',
			folder: this.props.folder, 
			search: this.props.currentSearchTerm
		});

		return sites;

	}

	startDragging = (e) => {

		this.setCoordinatesByIndex();

		if( this.dragStopping.current !== false ){ return }

		if( this.mouseDownTargetId ){
			window.requestAnimationFrame(()=>{

				// check if we've hit a 3px threshold to start dragging
				let threshold = 2;
				let xThresholdMet = Math.abs(this.state.mouseDownCoordinates?.x - e.clientX) > threshold;
				let yThresholdMet = Math.abs(this.state.mouseDownCoordinates?.y - e.clientY) > threshold;

				if (xThresholdMet || yThresholdMet) {
					window.removeEventListener('pointermove', this.startDragging);
				} else {
					return;
				}

				let draggedSiteID = parseInt(this.mouseDownTargetId);
				let sitesForRender = this.state.sitesForRender;
				let draggedSiteModel = sitesForRender.find((site)=>{ return site.id == draggedSiteID });
				let draggedSiteIndex = _.indexOf(sitesForRender, draggedSiteModel);
				let initialScrollY = this.gridRef.current.scrollTop;

				this.setState({
					draggingSiteId: draggedSiteID,
					draggedSiteEl: this.draggedSiteEl,
					draggedSiteModel: draggedSiteModel,
					draggedSiteIndex: draggedSiteIndex,
					isDragging: true,
					initialScrollY: initialScrollY
				})

			})
		}
	}

	onMouseDown = (e) => {
		
		if( this.state.isDragging || this.dragStopping.current === true ){ return }
		// Store event target id captured from DOM. Mousemove is bad at figuring this out.
		this.mouseDownTargetId = e.currentTarget.closest('[s-id]')?.getAttribute('s-id');
		this.draggedSiteEl = e.currentTarget; //Likely the parent element.
		this.preciseDragStartTarget = e.target; //More precise target, needed for knowing if drag started with the title area.
		this.draggingImgURL = e.currentTarget.closest('[s-id]')?.querySelector('img')?.getAttribute('src');
		this.draggingTargetPermissions = _.find(this.props.user.permissions, (siteItem) => { return siteItem.site_id === parseInt(this.mouseDownTargetId) });
		this.leftMenuBarWidth = document.querySelector('.left-menu-bar') ? document.querySelector('.left-menu-bar').getBoundingClientRect().width : false;

		if( this.preciseDragStartTarget.closest('.details') ){
			return 
		}

		if( this.props.slug === 'trash' ) return null // deleted sites page
		if( e.ctrlKey ) return null //context menu is open
		if( e.button === 2 || e.which === 2 ) return null //context menu is open

		// store the original click locations
		let siteRectangle = this.draggedSiteEl.querySelector('.site-preview');
		let rect = e.target.getBoundingClientRect();
		let x = e.clientX - rect.left; //x position within the element.
		let y = e.clientY - rect.top;  //y position within the element.

		const siteRect = siteRectangle?.getBoundingClientRect();

		if( this.preciseDragStartTarget.closest('.details') ){
			x = siteRect.width / 2;
			y = siteRect.height / 2;
		}

		if( !siteRect.width ){ return }

		this.setState({
			offsetDragStart: {x: x, y: y},
			mouseDownCoordinates: {x: e.clientX, y: e.clientY}
		}, ()=> { 
			// listen for a pointer move to indicate dragging
			window.addEventListener('pointermove', this.startDragging);
			// after dragging is confirmed, determine when it ends
			window.addEventListener('mouseup', this.handleMouseUp, { once: true });
		})
	}

	handleMouseUp = (e) => {
		// remove event on mouseup...
		window.removeEventListener('pointermove', this.startDragging);

		if ( this.state.isDragging && !this.state.isDropping ) {
			this.setState({ isDropping: true },()=>{
				this.onDragStop()
			})
		}

	}

	handleDragKeydown = (e) => {

		// escape while dragging
		if (e.which === 27 && this.state.isDragging) {
			// trick sort to think the dropped index is not changing
			this.setState({
				droppedIndex: null,
				isDragging: false,
				isDropping: true,
			}, ()=>{
				this.onDragStop();
			})
			e.preventDefault();
			
		}
	}

	onSiteMouseUp = (e) => {
		// if not dragging — prevent default and allow a click of the child site link element to propagate
		e.preventDefault();
		e.stopPropagation();

		if ( this.state.isDragging ) {
			this.setState({ isDropping: true },()=>{
				this.onDragStop()
			})
		}

	}

	transitionSitesOnCollectionChange = ( prevSitesForRender ) => {

		const oldSortIndexes = _.map(prevSitesForRender, site => site.id);
		// set old sort indexs and sort transition to trigger
		// inline styles added / removed from site item
		// creating the transition.
		this.setState({
			sortTransition: true,
			previousSortIndexes: oldSortIndexes,
		}, ()=> {
			this.setState({
				sortTransition: false,
				previousSortIndexes: null
			})
		}, ()=> {
			this.setCoordinatesByIndex();
		})

	}

	sortSites = (sortedSites, skipSorting) => {

		if(this.props.slug === 'trash') {
			return Promise.resolve();
		}

		const oldSortIndexes = _.map(this.getSitesForRender(), site => site.id);
		const newSortIndexes = _.map(sortedSites, site => site.id);

		this.props.folder.sites.forEach((site, index) => {
			site.sort = newSortIndexes.indexOf(site.site_id);
		});

		let sortedSitesLen = sortedSites.length;

		// Get new index from state
		const newIndex = this.state.droppedIndex;
		// Get rect from cached DOM coordinates
		let galleryChildRect = this.state.coordinatesByIndex[newIndex];
		if( !galleryChildRect && sortedSitesLen === newIndex ){
			galleryChildRect = this.state.coordinatesByIndex[newIndex - 1];
		}

		// Subtract mouse pos from gallery child rect. 
		let currentScrollY = this.gridRef.current.scrollTop;
		const returnTransitionX = this.state.mousePos.x - galleryChildRect.x;
		const returnTransitionY = this.state.mousePos.y - galleryChildRect.y + ( currentScrollY - this.state.initialScrollY );
		// Begin state setting chain and drop continue drop logic.

		this.setState({
			sitesForRender: sortedSites,
			sortTransition: true,
			previousSortIndexes: oldSortIndexes,
			sorting: true,
			isDragging: false,
			draggingItemReturnTransition: {
				x: returnTransitionX,
				y: returnTransitionY
			}
		}, ()=> {
			this.setState({
				sortTransition: false,
				previousSortIndexes: null
			})
		})

		// match the new sort index to the old of each site
		// transform it the distance of the difference
		// when complete
		if( skipSorting ){ 
			return Promise.resolve()
		} else {
			return this.props.updateFolder(this.props.folder);
		}

	}

	onDragStop = () => {

		this.clearScrollInterval();

		if( this.dragStopping.current ){ return }
		this.dragStopping.current = true;
		
		let sitesForRender = this.getSitesForRender();
		sitesForRender = [ ...sitesForRender];
		let oldIndex = this.state.draggedSiteIndex;
		let newIndex = this.state.droppedIndex;
		let skipSorting = oldIndex === newIndex ? true : false;

		// If draggedOverFolderId is set, we are dropping into a folder.
		if( this.state.draggedOverFolderId ){
			const targetFolder = this.props.folders.find( folder => folder.id == this.state.draggedOverFolderId );

			// Remove folder classes.
			[...document.querySelectorAll('#folder-menu a[folder-id].drop-highlight')].forEach(folder=>{
				folder.classList.remove('drop-highlight');
			})

			// this.flickerFolderOnDrop( this.state.draggedOverFolderId );

			// Use updateFolderItems to update the folder.
			if (this.state.altPressed === true) {
				// Duplicate the site into the folder
				this.initDuplicateSite( this.state.draggedSiteModel, null, targetFolder.id );
			} else {
				// Add the site to the folder via updateFolderItems
				this.props.updateFolderItems(targetFolder, {site_id: this.state.draggedSiteModel.id}, 'add');

				// If the current folder is anything other than the saved folder, remove the site from the current folder.
				// if (currentFolder.slug !== "all" && currentFolder?.id !== targetFolder.id) {
				// 	this.props.updateFolderItems(currentFolder, {id: this.state.draggedSiteModel.id}, 'remove');
				// }
			} 

			this.clearSortingState();

			return;
		}
		
		// no sort update? just clear everything
		if ( !newIndex && newIndex !== 0 ) {
			this.clearSortingState();

		// if there is a sort update
		} else {

			if( this.state.altPressed
				&& this.draggingTargetPermissions?.role !== 'Viewer' 
				&& this.draggingTargetPermissions?.role !== 'Inuse'
			){
				// if index is the same, we are not resorting.
				if( oldIndex === newIndex ){
					newIndex = null;
				}
				// Duplicate site on alt drop if no resort is required.
				this.initDuplicateSite(this.state.draggedSiteModel, newIndex, null);

				window.requestAnimationFrame(()=>{
					this.clearSortingState();
				})

				return

			}

			let siteInList = sitesForRender.splice(oldIndex, 1)[0];
			// add site in new index position
			sitesForRender.splice(newIndex, 0, siteInList);

			this.sortSites(sitesForRender, skipSorting).then(()=> { 
				window.requestAnimationFrame(()=>{
					this.clearSortingState(); 
				})
			})
		}

		window.removeEventListener('pointermove', this.startDragging)
	}

	clearSortingState = () => {

		this.setState({
			draggingSiteId: false,
			draggedSiteModel: null,
			isDragging: false,
			droppedIndex: null,
			showInsertionIndicator: false,
			draggedOverFolderId: null,
			draggingSiteHeight: null,
			sorting: false,
			adjacentIndexes: {
				above: -1,
				below: -1,
				left: -1,
				right: -1,
			},
			mousePos: {
				x: null,
				y: null,
			},
			draggingItemReturnTransition: {
				x: null,
				y: null
			},
			isDropping: false,
			initialScrollY: null,
			dragGhostImageLoaded: false,
		}, ()=> {
			this.dragStopping.current = false;
		})
	}

	flickerFolderOnDrop = ( folderId ) => {
		let flickerFolder = document.querySelectorAll('#folder-menu a[folder-id="'+folderId+'"]')[0];
		if( flickerFolder ){
			flickerFolder.classList.add('drop-highlight');
			setTimeout(()=>{
				flickerFolder.classList.remove('drop-highlight');
				setTimeout(()=>{
					flickerFolder.classList.add('drop-highlight');
					setTimeout(()=>{
						flickerFolder.classList.remove('drop-highlight');
					}, 90)
				}, 90)
			}, 90)
		}
	}

	handlePageScrollRegions = ( clientY ) => {

		let nearTop = clientY < 100;
		let nearBottom = clientY > (window.innerHeight - 100);

		if( this.props.currentSearchTerm ){ return }

		if (nearTop && !this.scrollIntervalRef.current ) {
            this.scrollContainer(-10, 'up');
            return
        }

        if (nearBottom && !this.scrollIntervalRef.current ) {
            this.scrollContainer(10, 'down');
            return
        }

        if (this.scrollIntervalRef.current 
        	&& (
        		(!nearTop && this.state.scrollingDirection === 'up')
        	 	|| (!nearBottom && this.state.scrollingDirection === 'down')
        	)
        ){
        	this.clearScrollInterval();
        	return
        }


	}

	clearScrollInterval = () => {
		clearInterval( this.scrollIntervalRef.current );
		this.scrollIntervalRef.current = null;
		this.setState({
			scrollingDirection: null
		})
	}

	scrollContainer = (step, direction) => {

		this.scrollIntervalRef.current = setInterval(() => { 
			// var scrollY = window.pageYOffset;
			// window.scroll(0, scrollY + step)
			this.gridRef.current.scrollBy(0, step);
		}, 16)

		this.setState({scrollingDirection: direction});

	}

	rectContainsCoords = (rect, x, y) => {

		return rect.x <= x && x <= rect.x + rect.width &&
			   rect.y <= y && y <= rect.y + rect.height;

	}

	onDragOver = (e) => {

		const debugDragOver = false;

		if ( !this.state.isDragging ){ return }

		this.handlePageScrollRegions( e.clientY );

		if( e.altKey 
			&& !this.state.altPressed 
			&& this.props.slug !== 'saved' 
			&& this.draggingTargetPermissions 
			&& this.draggingTargetPermissions?.role !== 'Viewer'
			&& this.draggingTargetPermissions?.role !== 'Inuse' 
		){
			this.setState({
				altPressed: true
			});
		}

		const draggedOverFolderEl = e.target.closest('#folder-menu a[folder-id]');

		let draggedOverFolderId = null;

		if( draggedOverFolderEl ){

			draggedOverFolderId = draggedOverFolderEl.getAttribute('folder-id');
			// Check if site beign dragged is already in the dragged over folder (using the draggedOverFolderId).
			// Add dropping class to folder
			if( this.props.folder?.id != draggedOverFolderId ){
				draggedOverFolderEl.classList.add('drop-highlight');
			}
			// Remove dropping class from all other folders
			[...document.querySelectorAll('#folder-menu a[folder-id].drop-highlight')].forEach(folder=>{
				if( folder !== draggedOverFolderEl ){
					folder.classList.remove('drop-highlight');
				}
			})
		} else {
			// Remove dropping class from all folders
			[...document.querySelectorAll('#folder-menu a[folder-id].drop-highlight')].forEach(folder=>{
				folder.classList.remove('drop-highlight');
			}
			)
		}

		// Short circuit if we have a search term.
		// Resorting while searching is not supported.
		// We allow dragging just so t
		if( this.props.currentSearchTerm && this.props.currentSearchTerm.length > 0 ){

			const dragIsOverNav = e.target.closest('.left-menu-bar') ? true : false;

			if ( dragIsOverNav ) {
				this.clearScrollInterval();
			}
	
			let offsetX = this.state.offsetDragStart.x;
			let offsetY = this.state.offsetDragStart.y;
	
			this.setState({
				mousePos: {
					x: e.clientX - offsetX,
					y: e.clientY - offsetY,
				},
				adjacentIndexes: { 
					left: null,
					right: null,
				},
				isDraggingOverNavigation: dragIsOverNav,
				droppedIndex: null,
				showInsertionIndicator: false,
				draggedOverFolderId,
			});

			return
		}

		// items can shift around 15px in any direction while indicating so we take them into account while measuring
		let dragMargins = this.dragMargins;
		// clear the drag map at first drag
		if( !this.state.showInsertionIndicator ){
			this.dragMap.clear();
		}

		const galleryEl = this.gridRef.current;
		// const galleryEl = document.querySelector('#siteslist');
		const galleryRect = galleryEl?.getBoundingClientRect();
		const scrollLeft = galleryEl.scrollLeft;
		const scrollTop = galleryEl.scrollTop;
	
		if (!galleryRect) return;
	
		const searchPoints = 60;
		let searchIncrement = 1.033;
		let sweepAngle = 2.399827721492203;
	
		let galleryChildren = Array.from(galleryEl.children).filter((el) => 
			!el.classList.contains('transition-measurer') && 
			!el.classList.contains('site-ghost')
		);
	
		const galleryItemRects = new Map();
	
		galleryChildren.forEach(el => {
			galleryItemRects.set(el, el.getBoundingClientRect());
		});
		// start by getting position of cursor relative to gallery position
		const elements = [];
		let x = e.clientX + -galleryRect.left + scrollLeft;
		let y = e.clientY + -galleryRect.top + scrollTop;
	
		let dist = 15;
		let i, angle = 0, posX, posY = 0;
	
		for (i = 0; i < searchPoints; i++) {
			angle += sweepAngle;
			dist = dist * searchIncrement + 2;
			posX = e.clientX + Math.cos(angle) * dist;
			posY = e.clientY + Math.sin(angle) * dist;
	
			for (const [galleryItemEl, galleryItemRect] of galleryItemRects) {
				if (this.rectContainsCoords(galleryItemRect, posX, posY)) {
					elements.push(galleryItemEl.closest('.site') || null);
					break;
				}
			}
		}
	
		elements.forEach(el => {
			if (!el || !galleryEl.contains(el)) return;
	
			let rect;
				if (this.dragMap.has(el)) {
					rect = this.dragMap.get(el);
				} else {

				// dragMargins add a bit of dead space around each element to make allowances for drag animation
				// without it, elements can slide back and forth each frame as it goes in and out of hit-range
				let clientRect = el.getBoundingClientRect();
				rect = {
					w: clientRect.width+-(dragMargins*2),
					h: clientRect.height+-(dragMargins*2),
					x1: clientRect.left + -galleryRect.left + dragMargins,
					x2: clientRect.left + clientRect.width +-galleryRect.left + -dragMargins,
					y1: clientRect.top +-galleryRect.top + dragMargins + scrollTop,
					y2: clientRect.top + clientRect.height +-galleryRect.top + -dragMargins + scrollTop
				};
				this.dragMap.set(el, rect);
			}
	
			if (
				x >= rect.x1 && x <= rect.x2 &&
				y >= rect.y1 && y <= rect.y2
			) {
				elements.push(el);
			}
		});
	
		const positions = elements.map(el => {

			const rect = this.dragMap.get(el);

			let inY = false,
				inX = false,
				above = false,
				below = false,
				toLeft = false,
				toRight = false,
				distance = 0,
				rise = 0,
				run = 0;

			if ( x >= rect.x1 && x <= rect.x2  ){
				inX = true;

				if ( x < rect.x1+rect.w*.5){
					toRight = true;
				} else {
					toLeft = true;
				}

				run = 0;

			} else if ( rect.x1 > x ){

				toRight = true;
				run = rect.x1 - x;

			} else if ( x > rect.x2 ){

				run = rect.x2 - x;		
				toLeft = true;
			}

			if( y >= rect.y1 && y <= rect.y2 ){
				inY = true;

				if ( y < rect.y1 + rect.h*.5 ){
					below = true;
				} else {
					above = true;
				}

				rise = 0;

			} else if ( rect.y1 > y ){
				below = true;
				rise = rect.y1 - y;

			} else if ( y > rect.y2 ){
				above = true;
				rise = rect.y2 - y;					
			}


			distance = Math.sqrt( (rise*rise)+(run*run) );	
			
			const index = galleryChildren.indexOf(el);

			return {
				inX, inY, distance, rise, run, above, below, toLeft, toRight,
				index: galleryChildren.indexOf(el),
				el, rect
			};
		});
	
		const defaultPos = { el: null, index: -1 };
		let toLeftPos = defaultPos,
			toRightPos = defaultPos,
			closestOnLeft = _.minBy(positions.filter(pos => pos.toLeft), 'distance'),
			closestOnRight = _.minBy(positions.filter(pos => pos.toRight), 'distance');
	
		if (closestOnLeft && closestOnRight) {
			if (Math.abs(closestOnRight.rise) < Math.abs(closestOnLeft.rise)) {
				toRightPos = closestOnRight;
				toLeftPos = positions.find(pos => pos.index === toRightPos.index - 1) || defaultPos;
			} else {
				toLeftPos = closestOnLeft;
				toRightPos = positions.find(pos => pos.index === toLeftPos.index + 1) || defaultPos;
			}
		} else {
			toLeftPos = closestOnLeft || defaultPos;
			toRightPos = closestOnRight || defaultPos;
		}
	
		let insertBeforeTarget = toRightPos.el || toLeftPos.el?.nextElementSibling;
		let insertBefore = insertBeforeTarget ? galleryChildren.indexOf(insertBeforeTarget) : null;
	
		let offsetX = this.state.offsetDragStart.x;
		let offsetY = this.state.offsetDragStart.y;
		let insertAtIndex = this.state.draggedSiteIndex < insertBefore ? insertBefore - 1 : insertBefore;


		const lastEl = galleryChildren[galleryChildren.length - 1];
		let lastElRect = null;
		let lastElBottomThreshold = null;
		// Make sure we have a last El and it's not the only item in the list
		if( lastEl && galleryChildren.length - 1 !== 0 ){
			lastElRect = lastEl.getBoundingClientRect();
			// Get the absolute bottom of the list in pixels
			lastElBottomThreshold = lastElRect.top + scrollTop + lastElRect.height;
		}

		if( y > lastElBottomThreshold || // If our current Y coordinate is greater than the bottom of the list OR...
			(
				( isNaN(insertAtIndex) || !insertAtIndex && insertAtIndex !== 0 || insertAtIndex === -1 ) // If we don't have an insert index AND...
				&& galleryChildren.length - 1 !== 0 // If we have more than 1 item to sort.
			)
		){
			// Set the left Pos to the final item in the list.
			toLeftPos  = { index: galleryChildren.length - 1 };
			toRightPos = { index: -1 };
			// Insert to the final item in the list.
			insertAtIndex = galleryChildren.length - 1;
		}

		const isDraggingOverNavigation = e.target.closest('.left-menu-bar') ? true : false;

		if ( isDraggingOverNavigation ) {
			this.clearScrollInterval();
			insertAtIndex = null;
		}
	
		const showInsertionIndicator = !isDraggingOverNavigation && !draggedOverFolderId;
	
		this.setState({
			mousePos: {
				x: e.clientX - offsetX,
				y: e.clientY - offsetY,
			},
			adjacentIndexes: { 
				left: toLeftPos.index,
				right: toRightPos.index,
			},
			isDraggingOverNavigation,
			droppedIndex: insertAtIndex,
			showInsertionIndicator,
			draggedOverFolderId,
		});
	}

	getTransformBySortIndexChange = (oldIndex, newIndex, siteId) => {

		if ( ( !oldIndex && oldIndex !== 0 ) || oldIndex === newIndex || oldIndex === -1) return null;

		let oldCoords = this.state.coordinatesByIndex[oldIndex];
		let newCoords = this.state.coordinatesByIndex[newIndex];

		if( !oldCoords ){
			oldCoords = this.state.prevCoordinatesByIndex[oldIndex];
		}

		let x = oldCoords?.x - newCoords?.x;
		let y = oldCoords?.y - newCoords?.y;

		if( x == null || x == undefined || isNaN(x) ){
			x = 0;
		}

		if( y == null || y == undefined || isNaN(y) ){
			y = 0;
		}

		return {transform: `translate3d(${x}px, ${y}px, 0) translateZ(0)`, transition: 'none'};
	}

	initDuplicateSite = ( siteModel, newIndex, folderId ) => {

		// If we have passed an index, insert site 
		const offset = newIndex ? 1 : 0;
		const insertBeforeThisSiteId = folderId ? null : newIndex !== null && newIndex != undefined ? this.state.sitesForRender[newIndex].id : siteModel.id;
		
		if( !this?.context?.duplicateCargoSite ){
			console.error('Duplicate site function not available from static context.');
			return
		}

		this.context.duplicateCargoSite(siteModel, insertBeforeThisSiteId, offset, folderId);
	}

	SiteTrashHeader = () => {
		return (
			<div id="header">
			<div className="title">Trash</div>
			<div className="message">
				Items in the Trash are removed after 30 days<br/>
				<AlertContext.Consumer>
					{(Alert) =><button 
						className="empty-trash"
						onClick={()=>{

							Alert.openModal({
								type: 'slide-confirm',
								header: 'Empty Trash?',
								slideMessage: 'Slide to empty Trash',
								message: `All items in the Trash will be permanently deleted. Once a site is deleted, it cannot be recovered.`,
								ignoreUnmount: true,
								onConfirm: () => {
									this.props.deleteAllSites()
								}
							});

						}}
					>
							Empty Trash
					</button> }
				</AlertContext.Consumer>
			</div>
		</div>
		)
	}

	render() {

		// wait for folders to fetch
		if( this.props.hasFolders === false ){
			return null
		}
		if( // only after we ran our folder fetch we should check to see if the path exists
			!this.props.folder && this.props.slug !== '' && this.props.slug != 'trash'
		) {
			return null;
		}

		if( ( this.props.slug === 'trash' && !this.props.trashFetched ) ||
		 	( this.props.slug === 'trash' && this.props.deletedSites.length === 0 )
		){
			return (
				<div id="siteslist">
					<div id="header">
						<div className="title">Trash</div>
						<div className="message">
							The Trash is empty
						</div>
					</div>
				</div>
			)
		}

		const { 
			// Drag related state
			mousePos, 
			isDragging, 
			adjacentIndexes, 
			isDraggingOverNavigation, 
			draggingItemReturnTransition, 
			draggingSiteId,
			draggedSiteIndex,
			draggedSiteModel,
			offsetDragStart,
			showInsertionIndicator,
			coordinatesByIndex,
			sorting,
			dragGhostImageLoaded,
			// Sites
			sitesForRender,
		} = this.state;

		const siteGhostPosition = mousePos.x && mousePos.y ? 
			`translate3d(${mousePos.x}px, ${mousePos.y}px, 0) translateZ(0)` 
			: `translate3d(0,0,0) translateZ(0)`;

		const isOverFolders = isDragging ? mousePos.x <= this.leftMenuBarWidth : false;

		const hideGhost = mousePos.x === null || mousePos.y === null ? true : false;

		let leftDragIndex = adjacentIndexes?.left + 1;
		let rightDragIndex = adjacentIndexes?.right + 1;
		let draggingSiteIndex = draggedSiteIndex + 1;
		let showLeftTransform = false;

		let siteSpacingTranslate = ``


			if( showInsertionIndicator && isDragging ){
				showLeftTransform = draggedSiteIndex === 0 ? true : leftDragIndex+1 !== draggingSiteIndex;
			}
			if( showInsertionIndicator 
				&& isDragging
				// do not show if the index is adjacent to original position
				&& (leftDragIndex !== draggingSiteIndex && showLeftTransform )
				&& (rightDragIndex !== draggingSiteIndex && rightDragIndex-1 !== draggingSiteIndex) 
			){
				siteSpacingTranslate = `
					.site:nth-child(${leftDragIndex}) {
						--base-translate: var(--translate-left) !important;
					}
					.site:nth-child(${rightDragIndex}) {
						--base-translate: var(--translate-right) !important;
					}
				`
			}

			// Determine the transform point for the dragged site based on this.state.mousePos and this.state.offsetDragStart
			let draggedSiteTransform = 'translate3d(0,0,0)';
																			
			let rect = null;
			let siteGhostStyle = {};

			if ( offsetDragStart?.x && offsetDragStart?.y && coordinatesByIndex[draggedSiteIndex] ) {
				rect = this.state.draggedSiteEl.querySelector('.site-preview').getBoundingClientRect();

				const offsetPercentY = offsetDragStart.y / rect.height;
				const offsetPercentX = offsetDragStart.x / rect.width;

				const offsetCenterX = offsetPercentX - 0.5;
				const offsetCenterY = offsetPercentY - 0.5;

				// Use offsetCenterY and offsetCenterX to calculate the necessary offset for the dragged site, given that it is being scaled down to 50%.
				const offsetX = offsetCenterX * rect.width;
				const offsetY = offsetCenterY * rect.height;

				draggedSiteTransform = `translate3d(${offsetX}px, ${offsetY}px, 0) translateZ(0)`;

				siteGhostStyle = {
					transform: siteGhostPosition,
					width:  rect.width,
					opacity: !dragGhostImageLoaded ? 0 : isDraggingOverNavigation ? 0.4 : 1,
					position: 'fixed',
					top: 0,
					left:0,
				}
			}

			// Places re-sorted element ontop of drag ghost.
			const dragReturnTransitionSyles = {
				transition: 'none',
				transform: `translate3d(${draggingItemReturnTransition.x}px, ${draggingItemReturnTransition.y}px, 0) translateZ(0)`,
				width:  rect?.width ? rect.width : 0,
			}

			const sitePreviewStyle = {
				transform: isDraggingOverNavigation ? `scale(0.5) ${draggedSiteTransform}` : 'scale(1) translateZ(0)',
			}
			
			return  (
				<>
					{isDragging && (
						<style>{`
							body, body * {
								${this.draggingTargetPermissions?.role === 'Viewer' || this.draggingTargetPermissions?.role === 'Inuse' 
									? 'cursor: default !important;' 
									: this.state.altPressed 
									? 'cursor: copy !important;' 
									: 'cursor: default !important;'}
							}
							${isOverFolders ? `
								body #manager { 
									z-index: 302 !important;
									pointer-events: none;
								}
								body .top-menu-bar {
									z-index: 303;
								}
							` : ''}
							${siteSpacingTranslate}
						`}</style>
					)}
				
					
					<MessageContext.Consumer>{(message) => (<>
						<div 
							id="siteslist" 
							className={`${isDragging ? 'dragging-list-item' : ''}${this.state.transitionClassList ? ' '+this.state.transitionClassList : ''}`.trim()} 
							ref={this.gridRef}
							onTransitionEnd={this.handleTransitionEnd}
						>
							{ sitesForRender.length > 0 ? (
								<>
								{this.props.slug === 'trash' ? <this.SiteTrashHeader /> : null}
							
								{sitesForRender.map((site, index) => (
									<Site 
										key={`site-${site.id}`} 

										site={site}
										id={site.id}
										containingFolderID={this.props.folder?.id}

										sort={index}
										
										isDragging={ site.id === draggingSiteId }
										isDragInProgress={ draggingSiteId ? true : false }
										
										onMouseDown={this.onMouseDown}
										
										onDraggingMouseUp={ this.onSiteMouseUp }
										onDragStop={ this.onDragStop }
										dropping={ sorting && site.id === draggingSiteId }
										draggingSiteId={ draggingSiteId }
										transitioning={ this.state.sortTransition }
										transitionStyles={ 
											site.id !== draggingSiteId ?
												this.getTransformBySortIndexChange(this.state.previousSortIndexes?.indexOf(site.id), index, site.id) 
												: dragReturnTransitionSyles 
										}

										message={message}
									/>

								))}
								{isDragging ? (
									<div 
										s-url={draggedSiteModel.site_url}
										s-id={draggedSiteModel.id}
										style={siteGhostStyle}
										className={`site site-ghost image-loaded${this.state.altPressed && draggedSiteModel.id ? ' indicate-duplication' : ''}${hideGhost ? ' hidden' : ''}`}
									>	
										<div className="site-buttons"></div>
										<a 
											href={'#'}
											draggable={false}
										>	
											<div className="site-preview" style={sitePreviewStyle}>
												<img 
													src={ this.draggingImgURL }
													width="1000"
													height="625"
													onLoad={() => this.setState({ dragGhostImageLoaded: true })}
												/>
											</div>	
										</a>									
									</div>
								) : null }

								<div className="transition-measurer" ref={this.transitionMeasureRef}></div>
								</>
							) : (
								<div className="title no-sites">
									{this.props.addSitesButton ?
										<div 
											className={`no-sites-button clickable`}
											onClick={()=>{
												if (this.props.addSitesButton) {
													this.props.history.push('/templates');
												}
											}}
										>	
											<div id="add-sites-placeholder">
												<BlankSiteTileIcon />
											</div>
											<div id="add-sites-plus">
												<DuplicatingIcon />
											</div>
										</div>
									: null }
								</div>
							)}
						</div>
					</>)}</MessageContext.Consumer>
				</>
			)
	}

	onKeyDown = e => {

		if( e.key === 'Alt' && this.props.slug !== 'saved'){
			this.setState({
				altPressed: true
			})
		}

		this.handleDragKeydown(e);

	}

	onKeyUp = e => {

		if( e.key === 'Alt'){
			this.setState({
				altPressed: false
			})
		}

	}

	componentDidMount() {

		window.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
		window.addEventListener('mousemove', this.onDragOver);
		window.addEventListener('clear-sorting-on-newtork-error', this.clearSortingState )

		// update state with actively rendered folder
		this.props.updateHomepageState({
			renderedFolder: this.props.folder?.id || null
		});

		this.dragMargins = this.transitionMeasureRef?.current?.getBoundingClientRect().width;

		this.setSitesForRender();

		if( this.props.savedFolderId === this.props.folder?.id && !this.props.templates.length ){
			// Give templates a chance to load.
			this.props.fetchTemplates()
			.then(() => {
				// Give templates a chance to process through redux.
					this.setSitesForRender();
			})
			.catch(() => {
				this.setSitesForRender();
			});
		}

	}

	componentWillUnmount() {

		window.removeEventListener('clear-sorting-on-newtork-error', this.clearSortingState )
		window.removeEventListener("keydown", this.onKeyDown);
		window.removeEventListener("keyup", this.onKeyUp);

		window.removeEventListener('pointermove', this.startDragging);
        window.removeEventListener('mouseup', this.handleMouseUp);

		this.props.updateHomepageState({
			renderedFolder: null
		});

	}

}

function mapReduxStateToProps(state, ownProps) {

	const activeFolderSlug = ownProps.match.params?.folder ?? null;
	const hasUiWindows = !_.isEmpty( state.uiWindows.byId );
	const sitesCount = state.sites.filter(site => !site.is_deleted).length;
	const forceAllFolder = state.auth.authenticated 
							&& sitesCount > 0 
							&& state.folders.find(folder => folder.slug === 'all') 
							&& ( state.homepageState.currentSearchTerm && state.homepageState.currentSearchTerm.length > 0 ) ? true : false;
	
	return {
		slug: activeFolderSlug,
		folder: !forceAllFolder ? state.folders.find(folder => folder.slug === (activeFolderSlug || 'all')) : state.folders.find(folder => folder.slug === 'all'),
		currentSearchTerm: state.homepageState.currentSearchTerm,
		hasFolders: state.homepageState.hasFolders,
		isMobile: state.homepageState.isMobile,
		sites: state.sites,
		deletedSites: state.deletedSites,
		folders: state.folders,
		trashFetched: state.homepageState.trashFetched,
		savedFolderId: state.account.saved_folder_id,
		templates: state.templates ? state.templates.flatMap(template => template.sites) : [],
		hasUiWindow: hasUiWindows,
		uiWindows: state.uiWindows,
		user: state.account,
		addSitesButton: sitesCount === 0 && state.folders.find(folder => folder.slug === (activeFolderSlug || 'all'))?.slug === 'all',
		sitePreview: state.sitePreview,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchTemplates           : actions.fetchTemplates,
		fetchSitesInFolder       : actions.fetchSitesInFolder,
		updateFolder             : actions.updateFolder,
		updateFolderItems        : actions.updateFolderItems,
		deleteAllSites           : actions.deleteAllSites,
		addUIWindow              : actions.addUIWindow,
		updateUserMeta			 : actions.updateUserMeta,
		updateHomepageState      : actions.updateHomepageState,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SitesList))