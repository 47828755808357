import { actionTypes } from "../actions";

import _ from "lodash";

const defaultJobsState = {
	initialFetch: false,
	paginationComplete: false,
	offset: 0,
	jobs: []
}

export default function (state = defaultJobsState, action) {

	if (!action.payload) {
		return state;
	}

	switch (action.type) {

		case actionTypes.FETCH_JOBS_PENDING:
			
			return {
				...state,
				initialFetch: true
			}

			break;

		case actionTypes.FETCH_JOBS_FULFILLED:

			const jobsFetched = action.payload.data;

			const checkEmptyDataObjects = (array) => {
				return array.some(item => Object.values(item).every(arr => Array.isArray(arr) && arr.length === 0));
			};

			const allPaginationResultsAreEmpty = checkEmptyDataObjects(jobsFetched);

			return {
				initialFetch: true,
				paginationComplete: jobsFetched.length < action.meta.amount || allPaginationResultsAreEmpty,
				offset: state.offset + jobsFetched.length,
				jobs: [
					...state.jobs,
					...jobsFetched
				]
			}

			break;

	}

	return state;

};