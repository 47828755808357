export const ProfessionsArr = [ 'Administrative', 'Art Direction', 'Branding', 'Creative Consulting', 'Creative Direction', 'Graphic Design', 'Logo Design', 'Motion Graphics', 'Package Design', 'Photography', 'Stylist', 'Web Design', 'Web development', 'Writing Copy',]

export const validateEmail = (inputVal) => {
    // Trim the input
    inputVal = inputVal.trim();

    // Handle empty input
    if (inputVal.length === 0) {
        return false;
    }

    // Email pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if inputVal is a valid email address
    if (emailPattern.test(inputVal)) {
        // Valid email address
        return true;
    }

    // Check if inputVal starts with 'mailto:'
    if (inputVal.toLowerCase().startsWith('mailto:')) {
        const email = inputVal.substring(7); // Remove 'mailto:'
        if (emailPattern.test(email)) {
            // Valid email address
            return true;
        } else {
            // Invalid email address after 'mailto:'
            return false;
        }
    }
}

export const validateURL = (inputVal) => {
    // Trim the input
    inputVal = inputVal.trim();

    // Handle empty input
    if (inputVal.length === 0) {
        return false;
    }
    // Check if inputVal starts with 'http://' or 'https://'
    const protocolPattern = /^(https?:\/\/)/i;

    if (!protocolPattern.test(inputVal)) {
        // Not starting with 'http://' or 'https://', so prepend 'https://'
        inputVal = `https://${inputVal}`;
    }

    // Handle inputVal being 'https://'
    if (inputVal === 'https://') {
        return false;
    }

    try {
        // Construct a new URL object
        const url = new URL(inputVal);
        // Check if the hostname has a TLD (contains at least one dot, and TLD is at least 2 characters)
        const hostnameParts = url.hostname.split('.');
        const tld = hostnameParts[hostnameParts.length - 1];
        if (hostnameParts.length < 2 || tld.length < 2) {
            return false;
        }

        // All checks passed, return true
        return true;
    } catch (err) {
        // Invalid URL
        return false;
    }
};