import React, { Component, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import { actions } from "../actions";
import { isServer } from "@cargo/common/helpers";
import _ from "lodash";
import FolderLinks from './folder-links';
import SearchMenu from './search-menu';
import { IntercomSetup } from './App';

const LeftMenuBar = ({ authenticated, canSearch, routeInfo, folders }) => {
	
	const leftBarRef = useRef(null);
	
	if (!authenticated || routeInfo.isPublicFolder ) { 
		return null;
	}

	return (
		<div ref={leftBarRef} className={`left-menu-bar`}>
			<div className="inner">
			<div className="background-fill-row"></div>
			<FolderLinks folders={folders} authenticated={authenticated} type="vertical" />
			<div className="background-fill"></div>
			{IntercomSetup()}
			{canSearch && <SearchMenu /> }
			</div>
		</div>
	);
}

function mapReduxStateToProps(state, ownProps) {

	const hasC3Sites = state.account?.sites?.filter( (site)=>{if (site?.version === 'Cargo3') {return site}} )?.length > 0;

	return {
		hasC3Sites,
		folders: state.folders,
		authenticated: state.auth.authenticated,
		canSearch: hasC3Sites && !ownProps.routeInfo.isCommunity
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateHomepageState: actions.updateHomepageState,
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(LeftMenuBar));

