import { actionTypes } from "../actions";

import _ from "lodash";

const defaultFilterState = {
    fullTime: false,
    partTime: false,
    temporary: false,
    remote: false,
    onSite: false,
    profession: 'default',
    location: null,
};

export default function (state = defaultFilterState, action) {

    if (!action.payload && action.type !== 'RESET_JOBS_FILTERS') {
        return state;
    }

    switch (action.type) {

        case actionTypes.SET_JOBS_FILTER:
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: value,
            };

        case actionTypes.SET_MULTI_JOBS_FILTERS:
            return {
                ...state,
                ...action.payload,
            };

        case actionTypes.RESET_JOBS_FILTERS:
            return { ...defaultFilterState };

        default:
            return state;
    }

};