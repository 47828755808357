import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { HOMEPAGE_ORIGIN } from "@cargo/common";
import { NavLink, useHistory } from 'react-router-dom';
import { IntercomSetup } from '../App';

import _ from 'lodash';

import { Button } from '@cargo/ui-kit/button/button';

const StaticPageNavigation = ({ closeStaticPage, authenticated, isMobile }) => {

    const LinkButton = ({label, route}) => {
        return (
            <NavLink exact
                to={route}
                className={`button-link`}
                onDragStart={(e)=>{e.preventDefault();}}
                onContextMenu={(e)=>{e.preventDefault();}}
                onClick={(e)=>{ 
                    const container = document.querySelector('.page-scroll');
                    if (container) {
                        container.scrollTo({ top: 0 });
                    }
                }}
            >{label}</NavLink>
        )
    }

	return <>
		<div className="static-page-navigation">

            {/* <object 
                className="🌐" 
                type="image/svg+xml" 
                data={`${PUBLIC_URL}/images/spinning-globe.svg`}
            ></object> */}

            <span className="first">
                <LinkButton
                    label="Rates & Services"
                    route="/information"
                />
                <br/>

                <LinkButton
                    label="Students"
                    route="/students"
                />
                <br/>

                {isMobile ? (
                    <>
                        <NavLink exact
                            className={`button-link`} 
                            // isActive={() => isCommunity} 
                            activeClassName="no-selected-state" 
                            to='/fonts'
                        >Fonts</NavLink>
                        <br/>
                    </>
                ) : null}

                <a
                    href="https://docs.cargo.site"
                    target="_blank"
                    className='button-link external'
                >Docs</a>
                <br/>

                

                <a
                    href="https://soundcloud.com/cargocollective"
                    target="_blank"
                    className='button-link external'
                >Useful Music</a>

                <br/>

                <a
                    href="https://instagram.com/cargoworld"
                    target="_blank"
                    className='button-link external'
                >Instagram</a>

                <br/>

                <span className="contact">
                    { IntercomSetup('text') }
                </span>
                
            </span>

			<span className="second">

                <LinkButton
                    label="Terms of Service"
                    route="/terms"
                />
                <br/>

                <LinkButton
                    label="Privacy Policy"
                    route="/privacy"
                />
                <br/>

                <LinkButton
                    label="Copyright Issues"
                    route="/copyright-issues"
                />
            
			</span>

            { authenticated ? (<>
                <span className="fixed-header">
                    Information
                </span>
                <span className="button-close">
                    <Button 
                        className="close-button"
                        label={<svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <line x1="10" y1="10" x2="90" y2="90" stroke="var(--ui-color-flat-reverse)" strokeWidth="10"/>
                            <line x1="10" y1="90" x2="90" y2="10" stroke="var(--ui-color-flat-reverse)" strokeWidth="10"/>
                        </svg>}
                        onClick={(e)=>{ closeStaticPage(); }}
                    />
                </span>
            </>) : null }

		</div>
	</>
}

export default StaticPageNavigation;