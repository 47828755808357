import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { basicFormikChangeListener as FormikChangeListener } from '@cargo/common';
import { Button } from '@cargo/ui-kit/button/button';
import { CheckBox } from '@cargo/ui-kit/checkbox/checkbox';
import { Select } from '@cargo/ui-kit/select/select';
import { ProfessionsArr } from './helpers';

import _ from 'lodash';

const JobsFilter = () => {

    const dispatch = useDispatch();

    const history = useHistory();
    const isHomePage = useSelector((state) => (state?.homepageState ? true : false));
    const allJobs = useSelector((state) => state.paginatedJobs?.jobs);
    const myJobs = useSelector((state) => state?.userJobs);
    const jobs = useMemo(() => (isHomePage ? allJobs : myJobs ), [allJobs, isHomePage, myJobs]);
    // user email...
    const email = useSelector((state) => state.account.email);

    // Select filter state from Redux
    const jobsFilter = useSelector((state) => state.jobsFilter);
    const { fullTime, partTime, temporary, remote, onSite, profession, location } = jobsFilter;

    // Initialize Formik with Redux state
    const initialValues = {
        'full-time': fullTime,
        'part-time': partTime,
        'temporary': temporary,
        'remote': remote,
        'on-site': onSite,
        'profession': profession,
        'location': location
    };

    // Function to filter jobs based on current filters
    const getFilteredJobs = (jobsList) => {
        // If no filters are applied, return all jobs
        const noEmploymentType = !fullTime && !partTime && !temporary;
        const noLocation = !remote && !onSite;
        const noProfession = !profession || profession === 'default';
        const noDefinedLocation = !location;

        if (noEmploymentType && noLocation && noProfession && noDefinedLocation) {
            return jobsList;
        }

        let filtered = jobsList;

        if( location ) {
            filtered = filtered.filter((job) => {
                if( job?.location_map?.label ){
                    return job.location_map.label.toLowerCase() === location.toLowerCase();
                }
            });
        }

        // Filter by employment type
        const employmentTypes = [];
        if (fullTime) employmentTypes.push('full time');
        if (partTime) employmentTypes.push('part time');
        if (temporary) employmentTypes.push('temporary');

        if (employmentTypes.length > 0) {
            filtered = filtered.filter((job) => employmentTypes.includes(job.employment_type.toLowerCase()));
        }

        // Filter by location
        if (remote || onSite) {
            filtered = filtered.filter((job) => {
                const jobLocation = job.location ? job.location.toLowerCase() : '';
                if (remote && !onSite) {
                    return jobLocation === 'remote';
                }
                if (!remote && onSite) {
                    return jobLocation !== 'remote';
                }
                if (remote && onSite) {
                    return true; // Include all jobs if both are selected
                }
                return true;
            });
        }

        // Filter by profession
        if (profession && profession !== 'default') {
            filtered = filtered.filter(
                (job) => job.profession === profession
            );
        }

        return filtered;
    };

    const setFilteredJobs = (filteredResults) => {
        dispatch({
            type: 'SET_FILTERED_JOBS',
            payload: filteredResults
        });
    };

    const setMultipleFilters = (filters) => {
        dispatch({
            type: 'SET_MULTI_JOBS_FILTERS',
            payload: filters
        });
    };

    const setFilter = (filter) => {
        dispatch({
            type: 'SET_JOBS_FILTER',
            payload: filter
        });
    };

    const resetFilter = (filter) => {
        dispatch({
            type: 'RESET_JOBS_FILTERS',
            payload: null
        });
    }

    // useEffect to handle filtering whenever jobs or filters change
    useEffect(() => {
        const filtered = getFilteredJobs(jobs);
        setFilteredJobs(filtered);
    }, [jobs, fullTime, partTime, temporary, remote, onSite, profession, location]);

    // Handle form reset
    const handleReset = (resetForm) => {
        resetForm();
        resetFilter();
        history.push( isHomePage ? '/jobs' : '/' );
    };

    // Handle form changes
    const handleChange = (changes) => {
        const changedKey = Object.keys(changes)[0];
        const newValue = changes[changedKey];

        // Handle employment type pseudo-radio group
        if (['full-time', 'part-time', 'temporary'].includes(changedKey)) {
            if (newValue) {
                // One value true, other values false.
                setMultipleFilters({
                    fullTime: changedKey === 'full-time',
                    partTime: changedKey === 'part-time',
                    temporary: changedKey === 'temporary',
                })
            } else {
                setFilter({
                    key: changedKey === 'full-time' ? 'fullTime' : changedKey === 'part-time' ? 'partTime' : 'temporary',
                    value: false,
                })
            }
        }

        // Handle location pseudo-radio group
        if (['remote', 'on-site'].includes(changedKey)) {
            if (newValue) {
                setMultipleFilters({
                    remote: changedKey === 'remote',
                    onSite: changedKey === 'on-site',
                });
            } else {
                setFilter({ key: changedKey === 'remote' ? 'remote' : 'onSite', value: false });
            }
        }

        // Handle profession selection
        if (changedKey === 'profession') {
            setFilter({ key: 'profession', value: newValue });
        }

        history.push( isHomePage ? '/jobs' : '/' );

    };

    const anyFilterApplied = fullTime || partTime || temporary || remote || onSite || (profession && profession !== 'default');

    if( !isHomePage ) { 
        return <span className="email">{email}</span>
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => { }}
        >
            {(formikProps) => {
                const { resetForm, values } = formikProps;

                return (
                    <>
                        <FormikChangeListener onChange={handleChange} />
                        <div className="job-filters ui-group text-ui">
                            <div className="filter-area-1">
                                <Button
                                    className={`filter-button text-button${!anyFilterApplied ? ' checked' : ''}`}
                                    onClick={() => handleReset(resetForm)}
                                >
                                    All
                                </Button>
                                <div className="ui-group-spacer"></div>
                            </div>
                            <div className="filter-area-2">
                                <Field
                                    component={CheckBox}
                                    parentClass={'text-checkbox'}
                                    name="full-time"
                                    label="Full&nbsp;time"
                                    labelIcon={<></>}
                                    labelIconOff={<></>}
                                />
                                <Field
                                    component={CheckBox}
                                    parentClass={'text-checkbox'}
                                    name="part-time"
                                    label="Part&nbsp;time"
                                    labelIcon={<></>}
                                    labelIconOff={<></>}
                                />
                                <Field
                                    component={CheckBox}
                                    parentClass={'text-checkbox'}
                                    name="temporary"
                                    label="Gig/Project"
                                    labelIcon={<></>}
                                    labelIconOff={<></>}
                                />
                                <div className="ui-group-spacer desktop"></div>
                            </div>
                            <div className="filter-area-3">
                                <Field
                                    component={CheckBox}
                                    parentClass={'text-checkbox'}
                                    name="remote"
                                    label="Remote"
                                    labelIcon={<></>}
                                    labelIconOff={<></>}
                                />
                                <Field
                                    component={CheckBox}
                                    parentClass={'text-checkbox'}
                                    name="on-site"
                                    label="Onsite"
                                    labelIcon={<></>}
                                    labelIconOff={<></>}
                                />
                                <div className="ui-group-spacer desktop"></div>
                                <Field
                                    noBars={true}
                                    component={Select}
                                    name="profession"
                                    className={`text-select${!values['profession'] || values['profession'] === 'default' ? ' unset' : ''
                                        }`}
                                    defaultDisplay={'All Professions'}
                                    prefix="↓"
                                    dynamicWidth={true}
                                >
                                    <option value={'default'}>All Professions</option>
                                    <option disabled></option>
                                    { _.map(ProfessionsArr, (profession) => (
                                        <option key={profession} value={profession}>{profession}</option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
};

export default JobsFilter;