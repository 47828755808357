import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath, withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import { paths, routes, routeTitles, staticPageTitles } from './routes';

import _ from 'lodash';

const WindowTitleManager = ({ layeredRouter }) => {

    const currentSearchTerm = useSelector(state => state.homepageState.currentSearchTerm);
    const folders = useSelector(state => state.folders);
    const sitesCount = useSelector(state => state.sites.count);
    const authenticated = useSelector(state => state.auth.authenticated);
    const publicFolders = useSelector(state => state.publicFolders);

    useEffect(() => {

        let title = 'Cargo';
        const topLayer = _.last(layeredRouter.layerStack);
        const bottomLayer = _.first(layeredRouter.layerStack);

        let useBottomLayer = true;
        // Make exceptions for certain routes to use the base layer or not.
        if (
            topLayer && topLayer.match.path === paths.ACCOUNT // Account just shows up at Cargo.
            || topLayer && topLayer.match.path === paths.STATIC_PAGES // Static pages should take priority if available.
        ) {
            useBottomLayer = false;
        }

        const evaluateLayer = useBottomLayer ? bottomLayer : topLayer;

        if (evaluateLayer) {
            if (currentSearchTerm && currentSearchTerm.length > 0) {
                // If there's a search term, set the title to include it
                title = `Cargo: ${currentSearchTerm}`;
            } else {
                const { route, match } = evaluateLayer;

                if (!route) {
                    // If no route is found, default title
                    title = 'Cargo';
                } else if (route.path === paths.STATIC_PAGES) {
                    const pageParam = match.params.page;
                    title = staticPageTitles[pageParam] || 'Cargo: Information';
                } else if (route.path === paths.FOLDER || (route.path === paths.ROOT && authenticated)) {
                    // Handle folder route
                    const activeFolderSlug = match.params?.folder ?? null;
                    // Methods pulled from siteslist component...
                    const forceAllFolder = authenticated
                        && sitesCount > 0
                        && folders.some(folder => folder.slug === 'all')
                        && (currentSearchTerm && currentSearchTerm.length > 0);
                    // Find specific folder if it's not the all folder
                    const folder = !forceAllFolder
                        ? folders.find(folder => folder.slug === (activeFolderSlug || 'all'))
                        : folders.find(folder => folder.slug === 'all');
                    // Set title to folder name
                    if (folder && folder.name && folder.name !== 'All') {
                        title = `Cargo: ${folder.name}`;
                    }
                } else if( route.path === paths.PUBLIC_FOLDER) { 

                    const activePublicFolderSlug = match.params?.folder ?? null;
                    const publicFolder = publicFolders.length > 0 ? publicFolders.find(folder => folder.slug === activePublicFolderSlug && folder.creatorId === match.params.userid ) : null;
                    title = `Cargo${publicFolder?.name ? ': '+publicFolder.name : ''}`;

                } else {
                    title = routeTitles[route.path] || 'Cargo';
                }
            }
        }

        // Set the title...
        document.title = title;

    }, [layeredRouter.layerStack, currentSearchTerm, folders, sitesCount, authenticated, publicFolders]);

    return null;
}

export default withRouter((WindowTitleManager));