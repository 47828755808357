import { useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';

export const useJobsListScroll = (scrollRef, initialJobsFetch, filteredJobsLength, jobsFilter) => {
    const lastScrollPosRef = useRef(0);
    const prevJobsFilterRef = useRef(jobsFilter);

    // Restore scroll position before the browser paints
    useLayoutEffect(() => {
        if (initialJobsFetch && filteredJobsLength > 0) {
            try {
                const savedScrollPos = sessionStorage.getItem('lastJobsScrollPosition');
                const scrollPos = savedScrollPos ? parseInt(savedScrollPos, 10) : 0;

                if (!isNaN(scrollPos) && scrollRef.current) {
                    scrollRef.current.scrollTop = scrollPos;
                    lastScrollPosRef.current = scrollPos;
                }

                sessionStorage.removeItem('lastJobsScrollPosition');
            } catch (error) {
                // console.error('Error restoring scroll position:', error);
            }
        }
    }, [initialJobsFetch, filteredJobsLength, scrollRef]);

    // Save scroll position on unmount
    useEffect(() => {
        return () => {
            try {
                sessionStorage.setItem('lastJobsScrollPosition', lastScrollPosRef.current);
            } catch (error) {
                // console.error('Error saving scroll position on unmount:', error);
            }
        };
    }, [initialJobsFetch, filteredJobsLength]);

    // Handle scroll events to save the current scroll position
    const handleScroll = () => {
        if (scrollRef.current) {
            lastScrollPosRef.current = scrollRef.current.scrollTop;
            try {
                sessionStorage.setItem('lastJobsScrollPosition', lastScrollPosRef.current);
            } catch (error) {
                // console.error('Error saving scroll position on scroll:', error);
            }
        }
    };

    // Reset scroll position and scroll to top when the filter values change
    useEffect(() => {
        // Compare current and previous jobsFilter
        if (prevJobsFilterRef.current && !_.isEqual(jobsFilter, prevJobsFilterRef.current)) {
            try {
                sessionStorage.removeItem('lastJobsScrollPosition');

                if (scrollRef.current) {
                    scrollRef.current.scrollTop = 0;
                    lastScrollPosRef.current = 0;
                }
            } catch (error) {
                console.error('Error resetting scroll position:', error);
            }
        }
        // Update previous filter
        prevJobsFilterRef.current = jobsFilter;
    }, [jobsFilter]);

    return handleScroll;
};