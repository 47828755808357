import { actionTypes } from "../actions";
import _ from "lodash";

export default function(state = [], action){

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.SET_FILTERED_JOBS: 

			return action.payload;

	}

	return state 

};
