import { actionTypes } from "../actions";
import _ from "lodash";

export default function (state = [], action) {

    if (!action.payload && !action.meta) {
        return state;
    }

    switch (action.type) {

        case actionTypes.FETCH_USER_JOBS_FULFILLED:

            if (action.payload.data) {
                return action.payload.data;
            }

            return state;
    }

    return state;
    
};