import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, NavLink, useLocation} from 'react-router-dom';

import _ from "lodash";

import AuthenticatedTopMenuBar from './authenticated-top-menu-bar';
import HomeMenu from './home-menu';

const TopMenuBar = ({ id }) => {
	
	const authenticated = useSelector((state) => state.auth.authenticated);

	const location = useLocation();
	const [returnTo, setReturnTo] = useState("/")
	
	useEffect(() => {
		if ( location && location.pathname !== returnTo && !location.pathname.includes('/community/') ) {
			setReturnTo( location.pathname );
		}
	}, [location]);

	return (
		<div id={id} className={`top-menu-bar${!authenticated ? ` clear` : ``}`}>
				{ authenticated && <AuthenticatedTopMenuBar returnTo={returnTo} />}
				{ !authenticated && <HomeMenu />}
		</div>
	);

}

export default withRouter(TopMenuBar);

