import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { withRouter } from 'react-router-dom';
import { generatePath } from "react-router";

import { MessageContext } from "@cargo/ui-kit/message/message-controller";
import { Messages } from '@cargo/ui-kit/language/messages';
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";

import { API_ORIGIN, WEB_HOSTNAME, helpers } from '@cargo/common';
import { handleHomePageScrollLock } from '@cargo/common/helpers';
import cargoFetch from '@cargo/fetch';
import { store } from '../index';

import { SiteDuplicationContext } from './site-duplication-provider';

import DesktopIcon from "@cargo/common/icons/desktop-icon.svg";
import MobileIcon from "@cargo/common/icons/mobile-icon.svg";

import CloseIcon from "@cargo/common/icons/nav-close.svg";
import NorthEastArrowIcon from "@cargo/common/icons/north-east-arrow.svg";

const getPreviewAuth = _.once(() => {
	const userId = store.getState().auth.data?.id;
	if (userId === undefined) {
		// In the absence of a user ID, assume we are in the public /templates and 
		// respond with dummy authentication data
		return Promise.resolve({
			data: {
				id: 0,
				access_token: 'Design Lab'
			}
		})
	}

	return cargoFetch.get(`${API_ORIGIN}/users/${userId}/previewtoken`)
})

class SitePreviewer extends Component {

	static contextType = SiteDuplicationContext;

	constructor(props) {

		super(props);

		this.state = {
			dimensions: {height: null, width: null},
			desktopDimensions: {height: null, width: null},
			measureFrame: {height: null, width: null},
			viewport: 'desktop',
			topAlignmentValue: null,
			leftAlignmentValue: null,
			previewAuth: null
		}

		this.iframeRef  = React.createRef();
		this.measureRef  = React.createRef();

		getPreviewAuth().then(({data: authData}) => {
			this.setState({
				previewAuth: btoa(JSON.stringify(authData))
			})
		})

	}

	componentDidMount = () => {
		window.addEventListener('resize', this.setAllDimensions);

		handleHomePageScrollLock(true);

		this.setAllDimensions();

		if( this.props.site ){
			this.props.updateSitePreview({
				previewingSite: true,
				previewSiteModel: this.props.site,
				containingFolderID: this.props.parentFolder?.id ?? null
			});
		}
		
	}

	componentDidUpdate = (prevProps) => {

		if(prevProps.site !== this.props.site) {
			this.setAllDimensions();
		}

		if( !this.props.hasTemplates && !this.props.loadingTemplates && !this.props.fromPublicFolder ){
			// Give templates a chance to load.
			this.props.fetchTemplates()
		}

		if(    
			   ( prevProps.site && this.props.site && this.props.parentFolder && prevProps.parentFolder )
			&& ( prevProps.site.id !== this.props.site.id || this.props.parentFolder.id !== prevProps.parentFolder.id )
		){
			this.props.updateSitePreview({
				previewingSite: true,
				previewSiteModel: this.props.site ?? null,
				containingFolderID: this.props.parentFolder?.id ?? null
			});
		}

		if(
			!this.props.site
			&& this.props.hasFolders
			&& this.props.hasTemplates
		) {
			// If we're on the design lab and we recognize the folder, then the site is not paginated yet.
			if( this.props.fromTemplates && this.props.parentFolder ){
				// Get the site outside normal pagination flow 
				this.props.fetchTemplateSite(this.props.parentFolder, this.props.siteToPreview).catch((err)=>{
					// Close preview if we can't fetch the site. Failure to fetch here returns a 404.
					this.closePreview();
				});

			} else {

				// close preview if there's no site but we have loaded
				// all folders and templates
				this.closePreview();

			}
		}

	}

	componentWillUnmount(){

		window.removeEventListener('resize', this.setAllDimensions)

		handleHomePageScrollLock(false);

		this.props.updateSitePreview({
			previewingSite: false,
			previewSiteModel: null,
			containingFolderID: null
		});
	}

	handleHotKey = (e) => {
			e.preventDefault();
			let activeWindow = Object.keys(this.props.activeUIWindows).length > 0;
			let alertActive = document.querySelector('.alert-window.active');
			let contextMenu = document.querySelector('.context-menu-layer');
			let loginWindow = document.querySelector('.login-window');
			if( activeWindow || alertActive || contextMenu || loginWindow ){ return }
			this.props.layeredRouter.closeOverlay();
	}

	closePreview = () => {
		
		this.props.layeredRouter.closeOverlay();

	}

	setAllDimensions = () => {
		this.setDimensions();
		window.requestAnimationFrame(()=>{
			this.setDupeButtonPosition();
		})
	}
	
	setDimensions = () => {

		// 25px padding left & right
		let defaultWidth = window.innerWidth - 50;
		// 40px top menu bar minus 25px padding top & bottom
		let	defaultHeight = window.innerHeight - 40 - 25 - 25;
		let	maxWidth = defaultWidth * .625 < defaultHeight ? defaultWidth : defaultHeight * 1.6;
		// Round to the nearest pixel
		maxWidth = Math.round(maxWidth);
		let	maxHeight = defaultWidth * .625 < defaultHeight ? defaultWidth * .625 : defaultHeight;
		// Round to the nearest pixel
		maxHeight = Math.round(maxHeight);

		this.setState({
			desktopDimensions: {height: maxHeight, width: maxWidth}
		});

		if( this.state.viewport !== 'desktop' ){
			this.setState({measureFrame: {height: maxHeight, width: maxWidth}});
		}

		if (this.state.viewport === 'desktop') {

			this.setState({
				dimensions: {height: maxHeight, width: maxWidth},
				measureFrame: {height: maxHeight, width: maxWidth},
			});

		} else {

			// let	maxHeight = 'calc(100vh - 60px - 46px)';
			// let	maxHeight = calc(100vh - 40px + var(--viewport-mobile-margin-admin-side)))
			let mobileMaxWidth = defaultWidth
			let	mobileMaxHeight = defaultHeight

			mobileMaxWidth = this.iframeRef?.current?.getBoundingClientRect()?.height;

			// Ensure mobileMaxWidth and mobileMaxHeight are equal to force initial mobile dimensions
			if (mobileMaxWidth !== mobileMaxHeight) {
				mobileMaxWidth = mobileMaxHeight;
			}1

			let width = mobileMaxWidth * .4615;
			width = Math.round(width) + 'px';

			this.setState({
				dimensions: {height: mobileMaxHeight, width: width }
			});
		}
	}

	setDupeButtonPosition = () => {
		const iframeRect = this.iframeRef?.current?.getBoundingClientRect();
		if (iframeRect) {
			const topAlignmentValue = iframeRect.top; // Align with the top of the iframe
			const rightAlignmentValue = iframeRect.right; // Align with the right of the iframe
			const leftAlignmentValue = iframeRect.left;
			this.setState({ 
				topAlignmentValue, 
				rightAlignmentValue, 
				leftAlignmentValue 
			});
		}
	}

	getTemplateSiteModel = (siteID) => {
		return _.filter(this.props.templateSites, {id: siteID})[0];
	}

	getUserSiteModel = (siteID) => {
		return _.filter(this.props.userSites, {id: siteID})[0];
	}

	nextSite = (previous) => {

		let nextSiteId;

		if ( this.props.parentFolder.is_template_folder || this.props.fromPublicFolder ) {

			if (this.props.sitesInFolder) {
				let isSortBased = this.props.site.sort || this.props.site.sort === 0;
				let currentSiteSort = isSortBased ? this.props.site.sort : this.props.sitesInFolder.findIndex(siteInFolder => siteInFolder.id === this.props.site.id);
				let nextSiteIndex;
				let nextSiteIndexWrapped;

				let nextAmount = previous ? -1 : 1;
				
				nextSiteIndex = isSortBased ? _.findIndex(this.props.sitesInFolder, siteInFolder => siteInFolder.sort === currentSiteSort + nextAmount) : currentSiteSort + nextAmount;
				nextSiteIndex = isSortBased ? nextSiteIndex : this.props.sitesInFolder[nextSiteIndex] ? nextSiteIndex : -1;

				if( previous ){
					nextSiteIndexWrapped = nextSiteIndex === -1 ? this.props.sitesInFolder.length - 1 : nextSiteIndex;
				} else {
					nextSiteIndexWrapped = nextSiteIndex === -1 ? 0 : nextSiteIndex;
				}
				
				if( this.props.sitesInFolder[nextSiteIndexWrapped]?.id === this.props.site.id && previous ) {
					nextSiteIndexWrapped = this.props.sitesInFolder.length - 2;
				}

				nextSiteId = this.props.sitesInFolder[nextSiteIndexWrapped]?.id;

			}

		} else {

			// Copy sites array for modification
			let iterableSites = [ ...this.props.parentFolder.sites ]
			// Remove all sites with a bad sort
			iterableSites = _.filter(iterableSites, (site)=>{ return site.sort !== -1 });
			// if we still have sites...
			if (iterableSites) {
				// Find the site model we've clicked on
				let currentFolderSiteModel = _.filter(iterableSites, {site_id: this.props.site.id})[0];
				// Get it's index out of the list
				let currentFolderIndex     = _.indexOf(iterableSites, currentFolderSiteModel);
				// Total site length
				let totalSites = iterableSites.length - 1;
				// Get the next folder ( or previous ) 
				let nextFolderIndex = !!previous ? (currentFolderIndex === 0 ? totalSites : currentFolderIndex - 1) : (currentFolderIndex === totalSites ? 0 : currentFolderIndex + 1);
				// grab the model
				let nextFolderSiteModel = iterableSites[nextFolderIndex];
				// Be sure we have access
				let isNextSiteModelInUserData = this.props.userSites.find((site)=> { return site.id == nextFolderSiteModel.site_id });

				let nextFullSiteModel = isNextSiteModelInUserData ? this.getUserSiteModel(nextFolderSiteModel.site_id) : this.getTemplateSiteModel(nextFolderSiteModel.site_id); 

				nextSiteId = nextFullSiteModel?.id;

			}
		}

		if(nextSiteId) {

			// generate new route
			const nextPath = decodeURIComponent(generatePath(this.props.match.path, {
				...this.props.match.params,
				siteid: nextSiteId
			}));

			// push it
			this.props.history.push(nextPath);

		}

	}

	copyDuplicateButtons = () => {
		if (!this.props.authenticated) {
			return null
		}
		if (!this.state.topAlignmentValue || !this.state.rightAlignmentValue) {
			return null;
		}
	
		return (<>
			{this.props.canDuplicate && (
				<button
					className="duplicate button-link"
					onClick={(e) => { this.context.handleSiteDuplication(this.props.site.id) }}
				>
					Use this template
					{/* <DuplicatingIcon /> */}
				</button>
			)}
			{this.props.canCopy && (
				<MessageContext.Consumer>
					{(Message) => (
						<button
							className="copy button-link"
							onClick={(e) => {
								let isBackdropCopy = this.props.site.website_title.toLowerCase().includes("backdrop");
								let copyText = !isBackdropCopy ? 'cargo:' + this.props.site.id : 'cargo:' + this.props.site.id + '?copybackdrop';
								navigator.clipboard.writeText(copyText);

								Message.showMessage({
									messageText: Messages.SITE_COPIED,
									duration: 2000,
									preventClickout: false,
									className: 'tall',
								});

								e.preventDefault();
							}}
						>
							Copy
							{/* <CopyIcon /> */}
						</button>
					)}
				</MessageContext.Consumer>
			)}
		</>);
	};

	render = () => {

		if(!this.props.site) {
			return <site-previewer>
				<div className="background"
					onPointerDown={this.closePreview}
				></div>
			</site-previewer>
		}

		const directLink = helpers.isLocalEnv ? `https://${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.direct_link;

		const measureFrameStyles = { height: this.state.measureFrame.height, width: this.state.measureFrame.width }
		// const minimumMarginVal = this.state.rightAlignmentValue < 45 ? 45 : this.state.rightAlignmentValue;
		const useProxy = !this.props.isLocal || this.props.isLocalSsr;
		const proxyPath = this.props.isLocalSsr ? `${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.display_url;
		// A preview URL of /site.preview/example.cargo.site/previewTokenBase64 will securely proxy the requested site
		let previewURL = useProxy ? `/site.preview/${proxyPath}/${this.state.previewAuth || 'missing-auth'}` : directLink;

		// Cargo 2 is never proxied nor sandboxed for site previews
		if (this.props.site.version === 'Cargo2') {
			previewURL = this.props.site.direct_link;
		} else if (! this.state.previewAuth) {
			previewURL = ''
		}
		
		const userToSite = _.find(this.props.permissions, (siteItem) => { 
			return siteItem.site_id === this.props.site.id
		});

		const showEditButton = this.props.authenticated && !this.props.isMobile && !this.props.fromTemplates && !this.props.fromSavedFolder && !this.props.fromPublicFolder && userToSite?.role !== 'Inuse';
		const showStartButton = !this.props.authenticated && !this.props.isMobile && this.props.fromTemplates && !this.props.fromPublicFolder && userToSite?.role !== 'Inuse';
		const showCopyButton = this.props.authenticated && !this.props.isMobile && this.state.topAlignmentValue && this.state.rightAlignmentValue && this.props.canCopy;
		const showDuplicateButton = this.props.authenticated && this.state.topAlignmentValue && this.state.rightAlignmentValue && this.props.canDuplicate;
		const renderProceedArea = (showEditButton || showStartButton || showCopyButton || showDuplicateButton) && !this.props.isMobile;

		return (
			<site-previewer>
				<div className="background" onPointerDown={this.closePreview}></div>
					<div className={`top-menu-bar`}>
						<a 
							className="site-link square button-link"
							href={directLink} 
							target="_blank"
						>
							<NorthEastArrowIcon />
						</a>

						{ renderProceedArea ? (
							<div className="proceed-area">

								{ showEditButton &&
									<button 
										className="edit button-link"
										onClick={(e)=>{
											let editLink = directLink;
											editLink = this.props.site.version === 'Cargo2' ? `${this.props.site.direct_link}/admin` : editLink + '/edit';
											editLink = userToSite?.role === 'Viewer' ? editLink + '/preview' : editLink;

											if (e.metaKey === true) {
												window.open(editLink, '_blank');
											} else {
												window.location = editLink;
											}
											return false
										}}
									>
										{userToSite?.role === 'Viewer' ? 'View Draft' : this.props.site.version === 'Cargo2' ? 'Edit in Cargo 2' : 'Edit'}
									</button>
								}

								{ showStartButton &&
									<button
										className="start top-area button-link"
										onClick={(e)=> { this.context.handleSiteDuplication(this.props.site.id) }}
									>
										Start with this template
									</button>
								}

								{ this.copyDuplicateButtons() }

							</div>
						) : null }

						<div className="background-fill"></div>

						<div className="viewport-buttons">
							<button 
								className={`desktop${this.state.viewport === 'desktop' ? ' selected' : ''}`}
								onPointerDown={(e)=>{
									this.setState({viewport: 'desktop'}, ()=>{
										this.setDimensions()
									})
								}}
							>
								<DesktopIcon />
							</button>
							<button 
								className={`mobile${this.state.viewport === 'mobile' ? ' selected' : ''}`}
								onPointerDown={(e)=>{
									this.setState({viewport: 'mobile'}, ()=>{
										this.setDimensions()
									})
								}}
							>
								<MobileIcon />
							</button>
						</div>

						<button
							className="close square"
							onClick={this.closePreview}
						>
							<CloseIcon />
						</button>
						
					</div>

				<div className="site-frame">
					<div className="site-frame-border">
						<iframe 
							src={previewURL}
							referrerPolicy="origin"
							allow="fullscreen"
							// Setting height inline for mobile insures proper width measurement is made without needing to resize or run the dimensions function twice.
							style={this.state.dimensions} 
							frameBorder="0"
							ref={this.iframeRef}
						></iframe>
					</div>
				</div>

				<div className="measuring-frame">
					<div className="measure" style={measureFrameStyles} ref={this.measureRef} />
				</div>
				
				<HotKey
					shortcut="esc"
					config={{ keyCode: 27 }}
					callback={this.handleHotKey}
					scope="preview"
				/>
			</site-previewer>
		)
	}
	
}

const getSitesInFolder = (state, folder) => {

	if(!folder) {
		return [];
	}

	return folder.sitesData || folder.sites || [];

}

function mapReduxStateToProps(state, ownProps) {

	// grab the base layer rendered below the preview
	const baseLayer = ownProps.layeredRouter.layerStack[0];

	// see if it rendered a public folder or design lab
	const fromPublicFolder = baseLayer.routeInfo.isPublicFolder;
	const fromTemplates = baseLayer.routeInfo.isTemplates;

	let parentFolder = null;

	if( fromPublicFolder ) {
		parentFolder = state.publicFolders.find(folder => folder.slug === ownProps.match.params.folderSlug);
	} else {
		if( state.sitePreview.containingFolderID ){
			// On design lab homepage where no single folder is rendered, we provide the containing folder before we open the preview
			parentFolder = [...state.folders, ...state.templates].find(folder => folder.id === state.sitePreview.containingFolderID )
		} else {
			parentFolder = 
			// find based on currently rendered folder
			[...state.folders, ...state.templates].find(folder => folder.id === state.homepageState.renderedFolder )
			// try matching the id to a template folder. "containingFolderId" should be filled in this case.
			|| _.find(state.templates, folder => {
				return getSitesInFolder(state, folder).find(siteInFolder => siteInFolder.id == ownProps.siteToPreview)
			});
		}
	}
	
	const folderSlugFromUrl = ownProps.location.pathname.match(/\/(?!templates\/)(.*?)\/preview/)?.[1] ?? null;

	if( !parentFolder && folderSlugFromUrl ){
		parentFolder = [...state.folders, ...state.templates].find(folder => { return folder.slug === folderSlugFromUrl }) ?? null;
	}

	const sitesInParentFolder = getSitesInFolder(state, parentFolder);

	const templateSites = state.templates ? state.templates.flatMap(template => template.sites) : [];
	// First check is designed to only find sites surfaced in public folders or templates folders.
	// Private user folders are sortable, and do not container all the site info we need.
	let siteModelFromParent = parentFolder ? parentFolder.sites.find(site => site.id == ownProps.siteToPreview) : null;
	// Second check is designed to find the site in the user's own folders from a larger pool of sites.
	let siteModel = siteModelFromParent ? siteModelFromParent : [...state.sites, ...templateSites].find(site => site.id == ownProps.siteToPreview);
		
	const canDuplicate = ( siteModel?.can_duplicate ) && !siteModel?.is_coming_soon && !fromPublicFolder;
	const canCopy      = siteModel?.can_copy && !siteModel?.is_coming_soon;

	return {
		isLocal     : CARGO_ENV === 'localhost',
		isLocalSsr  : CARGO_ENV === 'local-ssr',
		rootFolder   : state.folders[0],
		authenticated: state.auth.authenticated,
		templateSites: templateSites,
		userSites    : state.sites,
		permissions  : state.account.permissions,
		hasFolders   : state.homepageState.hasFolders,
		hasTemplates   : state.homepageState.hasTemplates,
		loadingTemplates: state.homepageState.loadingTemplates,
		slugFromUrl   : folderSlugFromUrl,
		site                : siteModel,
		parentFolder    	: parentFolder,
		sitesInFolder       : sitesInParentFolder,
		fromSavedFolder     : parentFolder?.slug === 'saved',
		canLoop             : sitesInParentFolder?.length > 1,
		canDuplicate        : canDuplicate,
		canCopy             : (state.auth.authenticated && canCopy) || (!state.auth.authenticated && !canDuplicate && canCopy),
		userId			    : state.account?.id,
		activeUIWindows: state.uiWindows.byId,
		fromPublicFolder,
		fromTemplates,
		isMobile 		  : state.homepageState.isMobile
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFolder: actions.updateFolder,
		addUIWindow: actions.addUIWindow,
		updateSitePreview: actions.updateSitePreview,
		fetchTemplateSite: actions.fetchTemplateSite,
		fetchTemplates: actions.fetchTemplates,
	}, dispatch);
}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SitePreviewer))
