import React, {useState, useEffect, useContext } from 'react';
import { DroppableContext } from "./droppable-context-wrapper"
import { NavLink } from 'react-router-dom';
import SortableFolders from './sortable-folder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { AlertContext } from "@cargo/ui-kit/alert/alert";
import { Button } from "@cargo/ui-kit/button/button";
// import { HotKeyProxy } from "./ui-kit";
import AddFolderIcon from "@cargo/common/icons/add-folder.svg";
import FolderIcon from "@cargo/common/icons/folder.svg";
import SavedFolderIcon from "@cargo/common/icons/saved-folder-star.svg";

import { isSafari } from "@cargo/common/helpers";


const FolderLinks = ({ folders, ...props }) => {

	const { setShouldAnimate } = useContext(DroppableContext);
	const history = useHistory();
	const [items, setItems] = useState(folders); 
	const { isMobile, createFolder } = props;
	const isSafariBrowser = isSafari();

	useEffect(() => {
		setItems(folders);
	}, [folders]);

	const savedFolder = folders.filter(folder => folder.name.toLowerCase() === 'saved')[0]
	const hasSavedSites = savedFolder?.sites ?  savedFolder?.sites?.length > 0 : false;

	if( !folders || ( folders && folders.length === 0 )){
		return null;
	}

	return (
	<>
		<div id="all-button">
			<div role="button">
				<span role="button">
					<NavLink exact
						to={'/'}
						activeClassName="active"
						className={`button-link${window.location.pathname === '/' ? ' selected' : ''}`}
						onClick={() => {setShouldAnimate(false);}}
						onDragStart={(e)=>{e.preventDefault();}}
						onContextMenu={(e)=>{e.preventDefault();}}
					>
						<div className="folder-icon"><FolderIcon /></div>
						<div className="folder-title">All</div>
					</NavLink>
				</span>
			</div>
		</div>
		{ hasSavedSites ? (
			<div id="saved-button">
				<div role="button">
					<span role="button">
						<NavLink exact
							to={'/saved'}
							activeClassName="active"
							className={`button-link${window.location.pathname === '/saved' ? ' selected' : ''}`}
							onClick={() => {setShouldAnimate(false);}}
							onDragStart={(e)=>{e.preventDefault();}}
							onContextMenu={(e)=>{e.preventDefault();}}
						>
							<div className="folder-icon"><SavedFolderIcon /></div>
							<div className="folder-title">Saved</div>
						</NavLink>
					</span>
				</div>
			</div>
		) : null }
		<div id="folder-menu" className={`${props.type ? props.type : '' }`}>
			<SortableFolders folders={ items } isMobile={ isMobile }  />
		</div>
		<AlertContext.Consumer>
			{(Alert) => <div className="add-folder-area">
				<div>
				<Button
					className="add-folder button-link"
					onClick={(e) => { Alert.openModal({
						header: 'New Folder',
						type: 'dialog',
						placeholder: 'Folder title...',
						className: isSafariBrowser ? 'safari' : '',
						focusInput: true,
						ignoreUnmount: true,
						maxInputLength: 100,
						// HotKeyProxy: HotKeyProxy,
						onConfirm: ( folderName ) => {
							if( folderName ){ createFolder( folderName ) }
						}
					})}}
				>
					<div className="folder-icon"><AddFolderIcon /></div>
				</Button>
				</div>
			</div>}
		</AlertContext.Consumer>
	</>
	);
}


function mapReduxStateToProps(state, ownProps) {
	
	return {
		sites: state.sites,
		email: state.account.email,
		name: state.account.full_name,
		isMobile: state.homepageState.isMobile
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		createFolder: actions.createFolder
	}, dispatch);

}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FolderLinks);

